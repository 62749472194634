import { AuthControl } from "../../authControl";
import { Action } from "../../models/action";
import { Campground, CampgroundPropsUser } from "../../models/campground";
import { Link } from "../../models/link";
import { Service, ServiceImpl } from "../service";
import { ResponseJSON } from "../serviceUtils";

export type FindCampgroundsParams = {
  name?: string,
  sourceKey?: string
}
export interface AdminCampgroundsService extends Service {
  getCampground(campgroundId: string): Promise<{campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground & { descriptionFileName?: string }}>;
  findCampgrounds(params: FindCampgroundsParams): Promise<Array<{campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground & { descriptionFileName?: string }}>>;
  updateCampground(campgroundId: string, campground: CampgroundPropsUser & { descriptionFileName?: string }): Promise<Campground & { descriptionFileName?: string }>;
}

export class AdminCampgroundsServiceImpl extends ServiceImpl implements AdminCampgroundsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async getCampground(campgroundId: string): Promise<{campground: Campground, campgroundOverrides: Campground}> {
    return await this.get(`/admin/v1/campgrounds/${campgroundId}`, transformCampgroundResponseJSON);
  }

  async findCampgrounds(params: FindCampgroundsParams): Promise<Array<{campground: Campground, campgroundOverrides: Campground}>> {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value);
    }
    
    return await this.get(`/admin/v1/campgrounds`, transformCampgroundsResponseJSON, searchParams);
  }


  async updateCampground(campgroundId: string, campground: CampgroundPropsUser): Promise<Campground> {
    return await this.patch(`/admin/v1/campgrounds/${campgroundId}`, campground, transformUpdateResponseJSON);
  }

};

type CampgroundJSON = {
  /**
   *   Canonical, unique, opaque id of the campground
   */
  campgroundId: string;

  /**
   * Source of the campground information
   */
  source: "recreationgov" | "campflare" | "wastateparks"

  /**
   * Opaque source-specific key that is unique amoung Campgrounds from the source specified by "source"
   * For instance, recreation.gov campgrounds use the FacilityID, and campflare campgrounds use the 
   * campground.id.
   */
  sourceKey: string;

  /**
   * Human-readable short descriptive name of the campground
   */
  name?: string;

  /**
   * Optional human-readable text to display as subtext, to override the defaults of 
   * recAreaName or organizationName, where those don't make sense for display. For example,
   * the "Lake Cunningham Campground" default subtext is the recAreaName from the Recreation.gov
   * RIDB data, which is "Zorinsky Lake". "Zorinsky Lake" is a nearby lake, so would be confusing,
   * and therefore an admin might choose to provide this override displaySubText to "Glenn Cunningham Lake"
   */
  displaySubText?: string;

  /**
   * Description of this Campground, if available. May contain Markdown. 
   * For Campflare campgrounds, by default this will be the "shortDescription"
   */
  description?: string;

  /**
   * Timestamp at which the campground was most recently reviewed
   */
  lastReviewedTimestamp?: Date,

  /**
   * Customer-facing display name to show for the most recent reviewer (e.g. "TimJ")
   */
  reviewerDisplayName?: string,
  
  /**
   * Optional slug (for e.g. deep link) for this campground
   */
  slug?: string,
    
  /**
   * URL to use for reservations, booking, or more information
   */
  reservationUrl?: string;

  /**
   * URL to use as a link to Campflare, if appropriate and available (will be undefined if source is not "campflare")
   */
  campflareUrl?: string;

  /**
   * URL to use as a link to TrekWeather, if available
   */
  trekWeatherUrl?: string;

  /**
   * URL to a thumbnail image of the campground, if any is available
   */
  thumbnailUrl?: string;

  /**
   * Alt Text to use for the thumbnail image of the campground, if both the thumbnailUrl and alt text is available.
   */
  thumbnailAlt?: string;

  /**
   * Credit Text to use for the thumbnail image of the campground, if both the thumbnailUrl and photo credit are available.
   */
  thumbnailCredit?: string;

  /** 
   * latitude of the campground, in decimal degrees, if known
   */
  lat?: number;

  /** 
   * longitude of the campground, in decimal degrees, if known
   */
  lng?: number;

  /**
   * Recreation area name to use for the campground, if available.
   */
  recAreaName?: string;

  /**
   * Organization name to use for the campground, if available
   */
  organizationName?: string;

  /**
   * ISO 3166-1 alpha-2 country code for the country of this campground, if available 
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  country?: string;

  /**
   * ISO-3166-2 region within the country specified by country, for this campground, if available
   * @see https://en.wikipedia.org/wiki/ISO_3166-2
   */
  region?: string;

  /**
   * Human-readable city name of the city for this campground, if available
   */
  city?: string;  

  /**
   * Actions is an ordered list of potential actions, in descending priority order (i.e. action[0] is the most highly recommended action)
   */
  actions?: Array<Action>
  googleMapsStaticMapUrl?: string;
  googleMapsSearchUrl?: string;

  descriptionFileName?: string

  /**
   * More information content for this campground, if available. This may include Markdown. 
   */
  moreInfo?: string;

  /**
   * Optional links to more information about this campground
   */
  moreInfoLinks?: Array<Link>;
}

type campgroundResponseJSON = ResponseJSON & { campground: CampgroundJSON , campgroundOverrides: CampgroundJSON};
function transformCampgroundResponseJSON(responseJSON: campgroundResponseJSON): {campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground } {
  const campground = { ...responseJSON.campground };
  if(campground.lastReviewedTimestamp) campground.lastReviewedTimestamp = new Date(campground.lastReviewedTimestamp);

  const campgroundOverrides = responseJSON.campgroundOverrides;
  if(campgroundOverrides.lastReviewedTimestamp) campgroundOverrides.lastReviewedTimestamp = new Date(campgroundOverrides.lastReviewedTimestamp);

  return { campground, campgroundOverrides }
}

type campgroundsResponseJSON = ResponseJSON & {campgrounds: Array<{ campground: CampgroundJSON , campgroundOverrides: CampgroundJSON}>};
function transformCampgroundsResponseJSON(responseJSON: campgroundsResponseJSON): Array<{campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground }> {

  const campgrounds = [...responseJSON.campgrounds];
  campgrounds.forEach((element) => {
    if(element.campground.lastReviewedTimestamp) element.campground.lastReviewedTimestamp = new Date(element.campground.lastReviewedTimestamp);
    if(element.campgroundOverrides.lastReviewedTimestamp) element.campgroundOverrides.lastReviewedTimestamp = new Date(element.campgroundOverrides.lastReviewedTimestamp);    
  })
  return campgrounds;
}

type updateResponseJSON = ResponseJSON & Campground;
function transformUpdateResponseJSON(responseJSON: updateResponseJSON): Campground & { descriptionFileName?: string } {
  delete responseJSON.status;
  const campground: Campground & { descriptionFileName?: string } = responseJSON;

  return campground;
}
