import { AuthControl } from "../authControl";
import { Campground } from "../models/campground";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export type FindCampgroundsParams = {
  slug?: string,
  limit?: number,
  offset?: number
}

export interface CampgroundsService extends Service {
  getCampground(campgroundId: string): Promise<Campground | undefined>;
  findCampgrounds(params: FindCampgroundsParams): Promise<Array<Campground>>;
}

export class CampgroundsServiceImpl extends ServiceImpl implements CampgroundsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl, true)
  };

  async getCampground(campgroundId: string): Promise<Campground> {
    return await this.get(`/v1/campgrounds/${campgroundId}`, transformCampgroundResponseJSON);
  }

  async findCampgrounds(params: FindCampgroundsParams): Promise<Array<Campground>> {
    const searchParams = new URLSearchParams({
      limit: params.limit ? String(params.limit) : "500",
      offset: params.offset ? String(params.offset) : "0"
    })

    if (params.slug) searchParams.append("slug", params.slug);

    return await this.get(`/v1/campgrounds`, transformCampgroundsResponseJSON, searchParams);
  }

};

type campgroundResponseJSON = ResponseJSON & Campground;
function transformCampgroundResponseJSON(responseJSON: campgroundResponseJSON): Campground {
  const response = {...responseJSON};
  if(response.lastReviewedTimestamp) response.lastReviewedTimestamp = new Date(response.lastReviewedTimestamp);
  delete response.status;
  return response;
}

type campgroundsResponseJSON = ResponseJSON & {campgrounds: Array<Campground>};
function transformCampgroundsResponseJSON(responseJSON: campgroundsResponseJSON): Array<Campground> {
  return [...responseJSON.campgrounds];
}
